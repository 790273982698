import { SERVICE_OPTIONS } from '~/components/constants';

export const getServiceInfoFromOptions = (
  serviceTypeId: number,
  serviceType?: string
): [string, string] => {
  const service =
    SERVICE_OPTIONS.find((option) => option.value === serviceType) ||
    SERVICE_OPTIONS.find((option) => option.serviceTypeId === serviceTypeId) ||
    SERVICE_OPTIONS[0];

  const serviceName = service.label;

  let serviceDescription = '';

  if (service.description) {
    serviceDescription = service.description;
  }

  return [serviceName, serviceDescription];
};
