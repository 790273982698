import { Divider, Footnote, Heading } from '@madpaws/design-system';
import React from 'react';

import styles from './ServiceDetails.module.css';
import { findServiceById } from '../../utils';

import type { ReactElement } from 'react';

type ServiceDetailsProps = {
  basePrice: number;
  hasDivider: boolean;
  serviceTypeId: number;
  timeUnit: string;
};

export const ServiceDetails = ({
  basePrice,
  serviceTypeId,
  hasDivider,
  timeUnit,
}: ServiceDetailsProps): ReactElement | null => {
  const service = findServiceById(serviceTypeId);

  if (service === undefined) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.mainContent}>
        <div className={styles.serviceInformation}>
          <div className={styles.serviceIcon}>{service.icon}</div>
          <div className={styles.serviceTitleAndDescription}>
            <div className={styles.serviceTitle}>{service.title}</div>
            <div className={styles.serviceDescription}>{service.description}</div>
          </div>
        </div>
        <div className={styles.priceInformation}>
          <Footnote>From</Footnote>
          <Heading alignment="rightAlign" size="small3x">
            ${basePrice}
          </Heading>
          <Footnote>a {timeUnit}</Footnote>
        </div>
      </div>
      {hasDivider && (
        <div className={styles.divider}>
          <Divider />
        </div>
      )}
    </div>
  );
};
