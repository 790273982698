import {
  Divider,
  Heading,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import styles from './PetsAccepted.module.css';

import type { ReactElement } from 'react';
import type { SitterProfileAcceptedPetType } from '~/common/types/sitter';

const { publicRuntimeConfig } = getConfig();

type Props = {
  petsAccepted: SitterProfileAcceptedPetType[];
};

const ACCEPTED_PET_ICON_SIZES = {
  width: 64,
  height: 64,
};

export const PetsAccepted = ({ petsAccepted }: Props): ReactElement | null => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  if (!petsAccepted.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>Pets accepted</Heading>
      </LayoutVerticalSpacer>

      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
        <div className={styles.items}>
          {petsAccepted.map(({ id, description, label, name }) => {
            const src = `${publicRuntimeConfig.staticPath}/images/icons/petTypes/${name}.svg`;

            return (
              <div className={styles.item} key={id}>
                <img {...ACCEPTED_PET_ICON_SIZES} alt={`${label} icon`} loading="lazy" src={src} />
                <div className={styles.label}>{label}</div>
                <div className={styles.description}>{description}</div>
              </div>
            );
          })}
        </div>
      </LayoutVerticalSpacer>
      <Divider />
    </div>
  );
};
