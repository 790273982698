import type { SearchFilters } from '~/common/types/search';
import type {
  BooleanProp,
  SitterPolicy,
  SitterProfileCard,
  SitterProfileDetails,
  SitterProfileHome,
  SitterProfilePets,
  SitterProfileSitterSettings,
} from '~/common/types/sitter';

export type AdditionalEventProperties = {
  TAFE_certification: boolean;
  accepts_last_minute_booking: boolean;
  accepts_long_term_booking: boolean;
  accepts_rwb: boolean;
  bio_length: number;
  booking_type: string;
  children_at_home?: boolean;
  first_aid_certificate: boolean;
  is_outdoor_fenced: boolean;
  is_vet_student: boolean;
  liquid_medication: boolean;
  num_repeat_guests: number;
  num_reviews: number;
  outdoor_area_type: string;
  owner_number_pets: number;
  owns_pets: boolean;
  pill_medication: boolean;
  police_check_complete: boolean;
  response_rate: number;
  rwb_service_provided: number[];
  service_type: string;
  star_rating: number;
  state: string;
  suburb: string;
  topical_medication: boolean;
};

export const getAdditionalEventProperties = (
  policy: SitterPolicy,
  searchFilters: SearchFilters,
  details: SitterProfileDetails,
  home: SitterProfileHome,
  experience: {
    experience: BooleanProp[];
    firstName: string;
  },
  card: SitterProfileCard,
  sitterSettings: SitterProfileSitterSettings,
  pets: SitterProfilePets
): AdditionalEventProperties => ({
  accepts_last_minute_booking: policy.lastMinuteBooking.value,
  accepts_long_term_booking: policy.minimumStay.value,
  accepts_rwb: sitterSettings.providesRwbPerService.length > 0,
  bio_length: details.description.length,
  booking_type: searchFilters.bookingType,
  children_at_home: details.about.childrenAtHome?.value,
  first_aid_certificate: details.about.firstAidCertificate.value,
  is_outdoor_fenced: home.conditions.backyard.includes('fenced'),
  is_vet_student: experience.experience[3].value,
  liquid_medication: experience.experience[6].value,
  num_reviews: card.totalReviews,
  num_repeat_guests: card.repeatGuests,
  outdoor_area_type: home.conditions.backyard,
  owner_number_pets: pets.pets.length,
  owns_pets: details.about.hasPets?.value,
  pill_medication: experience.experience[5].value,
  police_check_complete: details.about.hasPoliceCheckBadge?.value,
  response_rate: card.responseRate,
  rwb_service_provided: sitterSettings.providesRwbPerService,
  service_type: searchFilters.service.type,
  star_rating: card.rating,
  state: searchFilters.location.address.stateName,
  suburb: searchFilters.location.address.suburb,
  TAFE_certification: experience.experience[2].value,
  topical_medication: experience.experience[8].value,
});
