import { useEffect, useState } from 'react';

import { PAN_ELEMENT_ID, SCRIPT_ELEMENT_ID, AREA_10_CMAX_SCRIPT_URL } from './constants';

type Area10CmaxScriptSnippet = {
  error: boolean;
  loading: boolean;
};

// This script reqiures you to have div with id equal PAN_ELEMENT_ID mounted
export const useArea10CmaxScript = (articleId: string): Area10CmaxScriptSnippet => {
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const onError = (): void => {
    setIsLoading(false);
    setError(true);
  };

  const onSuccess = (): void => {
    setIsLoading(false);
  };

  useEffect(() => {
    const date = new Date().toLocaleDateString('en-ca').replace(/-/g, '');
    const pan = document.getElementById(PAN_ELEMENT_ID);

    const newScriptElement = document.createElement('script');

    newScriptElement.id = SCRIPT_ELEMENT_ID;
    newScriptElement.src = `${AREA_10_CMAX_SCRIPT_URL}/article_${articleId}.js?${date}`;
    newScriptElement.async = true;
    newScriptElement.onload = onSuccess;
    newScriptElement.onerror = onError;
    newScriptElement.async = true;

    pan?.appendChild(newScriptElement);

    return () => {
      const existingScriptElement = document.getElementById(SCRIPT_ELEMENT_ID);
      existingScriptElement?.remove();
    };
  }, [articleId]);

  return {
    loading,
    error,
  };
};
