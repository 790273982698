import {
  useMediumFromMediaQuery,
  Heading,
  LayoutVerticalSpacer,
  Divider,
} from '@madpaws/design-system';
import React from 'react';

import { SitterPetCard } from './SitterPetCard';
import styles from './SitterPets.module.css';

import type { ReactElement } from 'react';
import type { SitterProfilePets } from '~/common/types/sitter';

type SitterPetsProps = {
  sitterPets: SitterProfilePets;
};

export const SitterPets = ({ sitterPets }: SitterPetsProps): ReactElement | null => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const { firstName, pets } = sitterPets;
  if (!pets.length) {
    return null;
  }

  return (
    <>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
        <Divider />
      </LayoutVerticalSpacer>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>
          {firstName}&apos;s pets
        </Heading>
      </LayoutVerticalSpacer>

      <div className={styles.list}>
        {pets.map((pet) => (
          <SitterPetCard key={pet.id} pet={pet} />
        ))}
      </div>
    </>
  );
};
