import {
  DAYTIME_SERVICE_TYPE_IDS,
  DEFAULT_CHRONOLOGY_DETAILS,
  DEFAULT_PET_TYPES_DATA,
  DEFAULT_SERVICE_TYPE_ID,
  OVERNIGHT_SERVICE_TYPE_IDS,
  PET_TYPE_NAMES_TO_PET_TYPE_IDS,
  SERVICE_TYPES_SUPPORTING_RWB,
  SERVICE_TYPE_IDS_TO_SERVICE_TYPE_SLUGS,
  SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS,
} from '~/common/constants/app';
import {
  MADPAWS_PUBLIC_PHONE_NUMBER,
  ORGANIZATION_DATA,
  SERVICE_OPTIONS,
} from '~/components/constants';
import {
  BOOKING_TYPES,
  BOOKING_TYPE_MARKETPLACE,
  BOOKING_TYPE_RWB,
} from '~/components/utils/search';

import { hasDatesForGivenService } from '../SearchResults/utils';

import type { ReactNode } from 'react';
import type { Chronology, PetTypes, SearchFilters } from '~/common/types/search';
import type {
  SitterProfileAcceptedPetType,
  SitterProfileSitterSettings,
  SitterQuestionsAndAnswers,
} from '~/common/types/sitter';

type ConstructStructuredDataProps = {
  avatar: string;
  canonicalUrl: string | null;
  description: string;
  firstName: string;
  max: number;
  min: number;
  stateName: string;
  suburb: string;
};

export const getCanonicalUrl = (locationSlug: string, sitterProfileSlug: string): string | null => {
  if (!locationSlug || !sitterProfileSlug) {
    return null;
  }

  const webBaseUrl = process.env.WEB_BASE_URL;

  if (!webBaseUrl) {
    return null;
  }

  return `${webBaseUrl}/petsitter/${locationSlug}/${sitterProfileSlug}`;
};

export const constructStructuredData = ({
  avatar,
  firstName,
  min,
  max,
  stateName,
  suburb,
  canonicalUrl,
  description,
}: ConstructStructuredDataProps): string => {
  const LOCAL_BUSINESS_DATA = {
    '@context': 'http://schema.org/',
    '@type': 'LocalBusiness',
    address: {
      '@type': 'PostalAddress',
      name: stateName + ', ' + suburb,
    },
    description: `Pet sitter in ${suburb} ${stateName}. ${description}`,
    image: avatar,
    name: firstName,
    // For 'LocalBusiness' Structured data
    // we need to provide a valid phone number as an attribute.
    // But as we don't want to share such Sitter's details,
    // Mad Paws's public phone number is used here
    telephone: MADPAWS_PUBLIC_PHONE_NUMBER,
    priceRange: `$${min} - $${max}`,
    url: canonicalUrl,
  };

  return JSON.stringify([ORGANIZATION_DATA, LOCAL_BUSINESS_DATA]);
};

export const hasLessOrEqualCharactersThan = (limit: number, input: string): boolean =>
  input.length <= limit;

// Due to strong ties between booking and service type and their
// tendency to affect each other, their checks were combined in
// one single helper
// NOTE: exported for testing purposes only
export const validateBookingAndServiceTypes = (
  searchFilters: SearchFilters,
  sitterSettings: SitterProfileSitterSettings
): [string, string] => {
  const { bookingType, service } = searchFilters;
  const { providesRwbPerService, acceptedServiceTypes } = sitterSettings;

  let newBookingType = bookingType;
  let newServiceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[service.type];

  // App check for booking type
  if (!BOOKING_TYPES.includes(bookingType)) {
    newBookingType = BOOKING_TYPE_MARKETPLACE;
  }

  // Source of truth are services that sitter actually provides.
  // In case we need to reset service type - booking type is
  // a secondary thing and will be checked later
  if (!acceptedServiceTypes.includes(newServiceTypeId)) {
    newServiceTypeId = acceptedServiceTypes[0] || DEFAULT_SERVICE_TYPE_ID;
  }

  // Validate if we allowed to keep RWB if selected
  if (newBookingType === BOOKING_TYPE_RWB && !providesRwbPerService.includes(newServiceTypeId)) {
    newBookingType = BOOKING_TYPE_MARKETPLACE;
  }

  return [newBookingType, SERVICE_TYPE_IDS_TO_SERVICE_TYPE_SLUGS[newServiceTypeId]];
};

// This method is to remove from chronology dates listed in
// unavailable dates list depending on sitter's availability.
// In case of RWB - uncheck specific days if in next 4 weeks this week day
// intersects with unavailable date.
// NOTE: exported for testing purposes only
export const filterNotValidDates = (
  chronology: Chronology,
  unavailableDates: string[],
  serviceTypeId: number,
  isRwbRequested: boolean
): Chronology => {
  // Create new data set from sitter's unavailable dates array
  // to it use instead of find/filtering Array methods
  // in order to save performance when it comes to find dates intersections
  const unavailableDatesSet: Set<string> = new Set(unavailableDates);

  // Find intersections in selected and unavailable dates for RWB case
  if (isRwbRequested && SERVICE_TYPES_SUPPORTING_RWB.includes(serviceTypeId)) {
    if (chronology.rwbStartDate && unavailableDatesSet.has(chronology.rwbStartDate)) {
      chronology.rwbStartDate = '';
    }

    return chronology;
  }

  // Find intersections in selected and unavailable dates
  // for overnight services cases
  if (OVERNIGHT_SERVICE_TYPE_IDS.includes(serviceTypeId)) {
    if (chronology.startDate && unavailableDatesSet.has(chronology.startDate)) {
      chronology.startDate = '';
    }

    if (chronology.endDate && unavailableDatesSet.has(chronology.endDate)) {
      chronology.endDate = '';
    }

    return chronology;
  }

  // Find intersections in selected and unavailable dates
  // for daytime services cases
  if (DAYTIME_SERVICE_TYPE_IDS.includes(serviceTypeId)) {
    if (chronology.scheduledDates && chronology.scheduledDates.length) {
      chronology.scheduledDates = chronology.scheduledDates.filter(
        (date) => !unavailableDatesSet.has(date)
      );
    }

    return chronology;
  }

  return chronology;
};

// In case dates structure doesn't fit service type - reset chronology data
// NOTE: exported for testing purposes only
export const validateDates = (
  searchFilters: SearchFilters,
  unavailableDates: string[]
): Chronology => {
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[searchFilters.service.type];

  if (!hasDatesForGivenService(searchFilters, serviceTypeId)) {
    return DEFAULT_CHRONOLOGY_DETAILS;
  }

  const isRwbRequested = searchFilters.bookingType === BOOKING_TYPE_RWB;

  return filterNotValidDates(
    searchFilters.chronology,
    unavailableDates,
    serviceTypeId,
    isRwbRequested
  );
};

// NOTE: exported for testing purposes only
export const filterNotValidPets = (
  requestedPetTypes: PetTypes,
  acceptedPetTypes: number[]
): PetTypes => {
  const filteredPetTypes = { ...DEFAULT_PET_TYPES_DATA };

  for (const requestedPetTypeName in requestedPetTypes) {
    const petTypeId = PET_TYPE_NAMES_TO_PET_TYPE_IDS[requestedPetTypeName];

    filteredPetTypes[requestedPetTypeName] = acceptedPetTypes.includes(petTypeId)
      ? requestedPetTypes[requestedPetTypeName]
      : '';
  }

  return filteredPetTypes;
};

const validatePets = (
  searchFilters: SearchFilters,
  sitterSettings: SitterProfileSitterSettings
): PetTypes => {
  const hasRequestedPets = Object.values(searchFilters.petTypes).some((petAmount) => !!petAmount);

  if (hasRequestedPets) {
    return filterNotValidPets(searchFilters.petTypes, sitterSettings.acceptedPetTypes);
  }

  return searchFilters.petTypes;
};

export const filterSearchFilters = (
  searchFilters: SearchFilters,
  sitterSettings: SitterProfileSitterSettings
): SearchFilters => {
  const [bookingType, serviceType] = validateBookingAndServiceTypes(searchFilters, sitterSettings);
  searchFilters.bookingType = bookingType;
  searchFilters.service.type = serviceType;
  searchFilters.petTypes = validatePets(searchFilters, sitterSettings);

  return searchFilters;
};

type ServiceOptionsType =
  | {
      description?: string | undefined;
      icon: ReactNode;
      label: string;
      serviceTypeId: number;
      title: string;
      value: string;
    }
  | undefined;

export const findServiceById = (serviceId: number): ServiceOptionsType =>
  SERVICE_OPTIONS.find((service) => service.serviceTypeId === serviceId);

type QnAProps = {
  answer: string[];
  question: string;
};

export const mapObjectToQnAProps = (obj: SitterQuestionsAndAnswers): QnAProps[] =>
  Object.entries(obj)
    .map(([, value]) => ({
      answer: [value.value],
      question: value.name,
    }))
    .filter(({ question, answer }) => question !== undefined && answer[0] !== '');

export const formatDateIntoMonthYear = (timestamp: number): string => {
  // Convert timestamp to milliseconds
  const milliseconds = timestamp * 1000;

  // Create a new Date object
  const date = new Date(milliseconds);

  // Get the month and year
  const month = date.toLocaleString('default', { month: 'short' }); // Short month name (e.g., Jan, Feb, etc.)
  const year = date.getFullYear();

  // Format the date in month-year format
  const formattedDate = `${month} ${year}`;

  return formattedDate;
};

export const countPets = (petTypes: PetTypes): number =>
  Object.values(petTypes).reduce(
    (totalPets, currentValue) => totalPets + (parseInt(currentValue, 10) || 0),
    0
  );

export const calculateNumberOfDays = (
  startDate: string | undefined,
  endDate: string | undefined
): number | undefined => {
  if (!startDate || !endDate) {
    return undefined;
  }

  const startDateTime = new Date(startDate).getTime();
  const endDateTime = new Date(endDate).getTime();

  const millisecondsPerDay = 1000 * 60 * 60 * 24;
  const differenceInMilliseconds = Math.abs(endDateTime - startDateTime);

  return Math.ceil(differenceInMilliseconds / millisecondsPerDay);
};

export const sitterAcceptsPetType = (
  petTypeId: number,
  petObjects: SitterProfileAcceptedPetType[]
): boolean => petObjects.some((pet) => pet.id === petTypeId);
