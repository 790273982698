import Head from 'next/head';
import React from 'react';

import { constructStructuredData } from '../utils';

import type { SearchFilters } from '~/common/types/search';
import type { SitterProfile as SitterProfileProps } from '~/common/types/sitter';

type Props = {
  canonicalUrl: string | null;
  searchFilters: SearchFilters;
  sitterProfile: SitterProfileProps;
};

export const PageHead = ({
  canonicalUrl,
  searchFilters,
  sitterProfile,
}: Props): React.ReactElement => {
  const DEFAULT_PAGE_TITLE = 'Pet Sitting & Pet Boarding Services | Mad Paws';
  const DEFAULT_PAGE_DESCRIPTION =
    'Find trusted pet sitters on Madpaws.com.au. Book dog sitting, dog boarding and dog walking online. Book now for top-notch care for your furry friend.';

  const {
    name,
    card: { firstName, area, avatar },
    servicesOffered,
    details: { description },
  } = sitterProfile;
  const {
    location: {
      address: { stateName, suburb },
    },
  } = searchFilters;
  const rates = servicesOffered?.sitterProfileServices.map((service) => service?.basePrice);
  const max = Math.max(...(rates.length ? rates : [0]));
  const min = Math.min(...(rates.length ? rates : [0]));

  const PAGE_TITLE = name && area ? `${name} | Mad Paws Pet Sitter | ${area}` : DEFAULT_PAGE_TITLE;

  const seoStructuredData = {
    stateName,
    suburb,
    canonicalUrl,
    description,
    min,
    max,
    firstName,
    avatar,
  };

  return (
    <Head>
      <title>{PAGE_TITLE}</title>
      <meta content={DEFAULT_PAGE_DESCRIPTION} name="description" />

      {/* Facebook Open Graph */}
      <meta content={PAGE_TITLE} property="og:title" />
      <meta content={DEFAULT_PAGE_DESCRIPTION} property="og:description" />
      <meta content={avatar} property="og:image" />
      {canonicalUrl && <meta content={canonicalUrl} property="og:url" />}

      {/* Twitter */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={PAGE_TITLE} name="twitter:title" />
      <meta content={DEFAULT_PAGE_DESCRIPTION} name="twitter:description" />
      <meta content={avatar} name="twitter:image" />
      <meta content="website" property="og:type" />

      {/* see more details about canonical tags */}
      {/* here: https://ahrefs.com/blog/canonical-tags/ */}
      {canonicalUrl && <link href={canonicalUrl} rel="canonical" />}

      {/* Structured Data for SEO purposes */}
      {/* see more details here: https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data */}
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/jsx-sort-props, react/no-danger
        dangerouslySetInnerHTML={{
          __html: constructStructuredData(seoStructuredData),
        }}
      />
    </Head>
  );
};
