import {
  Divider,
  Heading,
  LayoutGridItem,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import { PAN_ELEMENT_ID } from '~/utils/area10ProgramaticContent/constants';
import { useArea10CmaxScript } from '~/utils/area10ProgramaticContent/hooks';

import styles from './SitterSeoProgrammaticContent.module.css';

type Props = {
  area: string;
  articleId: string;
};

export const SitterSeoProgrammaticContent = ({ articleId, area }: Props): React.ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const { error } = useArea10CmaxScript(articleId);
  const heading = `Pet Sitters in ${area.replace(',', '')}`;

  if (error) {
    return <></>;
  }

  return (
    <LayoutGridItem placement={{ small: [1, 13], medium: [2, 12], large: [2, 12] }}>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
        <LayoutVerticalSpacer space="large1x">
          <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>{heading}</Heading>
          <div className={styles.wrapper} id={PAN_ELEMENT_ID}></div>
        </LayoutVerticalSpacer>
        <Divider />
      </LayoutVerticalSpacer>
    </LayoutGridItem>
  );
};
