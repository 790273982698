import {
  Divider,
  IconFlag,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import styles from './ReportProfile.module.css';

import type { ReactElement } from 'react';

type Props = {
  id: number;
  name: string;
};

export const ReportProfile = ({ id, name }: Props): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const emailHref = `mailto:service@madpaws.com.au?subject=Sitter Profile flagged as inappropriate: ID: ${id} Name: ${name}&body=Hi there, I'd like to flag this Sitter Profile as inappropriate. Sitter ID: ${id}, Sitter name: ${name}`;

  return (
    <>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large5x'}>
        <a className={styles.link} href={emailHref}>
          <IconFlag />
          Report Profile
        </a>
      </LayoutVerticalSpacer>
      {isMediumFromViewport && <Divider />}
    </>
  );
};
