import { useQuery } from '@apollo/client';
import {
  AnimatedSpinner,
  Body,
  Divider,
  Heading,
  IconStar,
  LayoutVerticalSpacer,
  Pagination,
  formatRatingScore,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import useConstant from 'use-constant';

import styles from './CustomerReviews.module.css';
import { DotDivider } from './DotDivider/DotDivider';
import { Review } from './Review/Review';
import { CUSTOMER_REVIEWS_ID } from '../components/ReviewsLink/constants';
import { GET_SITTER_REVIEWS } from '../serverSideProps/queries';

import type { LinkComponent } from '@madpaws/design-system';
import type { ReactElement } from 'react';
import type { SitterProfileCard } from '~/common/types/sitter';

type CustomerReviewsProps = {
  canonicalUrl: string | null;
  sitterCard: SitterProfileCard;
};

// TODO (WEB-1549) Remove after Design System update
Link.defaultProps = {
  legacyBehavior: true,
};

const truncateUrl = (url: string): string => {
  // Find the position of the first occurrence of '/' after 'https://'
  const startIndex = url.indexOf('/', 'https://'.length);
  // Extract the substring starting from that position
  const truncatedUrl = url.substring(startIndex);
  return truncatedUrl;
};

export const CustomerReviews = ({
  canonicalUrl,
  sitterCard,
}: CustomerReviewsProps): ReactElement | null => {
  const { firstName, rating, totalReviews, sitterId } = sitterCard;
  const isMediumFromViewport = useMediumFromMediaQuery();
  const reviewsPerPage = 10;

  const { refetch, data, loading } = useQuery(GET_SITTER_REVIEWS, {
    variables: {
      sitterId: sitterId,
      limit: reviewsPerPage,
      page: 1,
    },
    // to skip executing the query during server-side rendering
    ssr: false,
  });
  const [currentOffset, setCurrentOffset] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);
  const [currentTotal, setCurrentTotal] = useState(0);
  const [reviewsToDisplay, setReviewsToDisplay] = useState([]);
  const [hasPagination, setHasPagination] = useState(false);

  const truncatedUrl = truncateUrl(canonicalUrl ?? '');

  useEffect(() => {
    if (!data) {
      return;
    }
    const {
      pageInfo: { offset, size, total },
      reviews: reviewData,
    } = data.sitterReviews;

    setCurrentOffset(offset);
    setCurrentSize(size);
    setCurrentTotal(total);
    setReviewsToDisplay(reviewData);
    setHasPagination(total > size);
  }, [data]);

  // define a single instance of debounced function per component
  const handleButtonClick = useConstant(() =>
    AwesomeDebouncePromise((page: number): void => {
      refetch({ page });
    }, 400)
  );

  return (
    <div className={styles.root} id={CUSTOMER_REVIEWS_ID}>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>
          <div className={styles.averageRatingAndTotalReviews}>
            <IconStar />
            <div className={styles.averageRating}>{formatRatingScore(rating)}</div>
            <DotDivider />
            <div>{totalReviews} ratings</div>
          </div>
        </Heading>
      </LayoutVerticalSpacer>

      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
        {loading ? (
          <div className={styles.loading}>
            <AnimatedSpinner />
          </div>
        ) : reviewsToDisplay && reviewsToDisplay.length > 0 ? (
          <div>
            <ul role="list">
              {reviewsToDisplay.map((review, index) => (
                <li key={index}>
                  <LayoutVerticalSpacer space={isMediumFromViewport ? 'large2x' : 'large1x'}>
                    <Review review={review} />
                    {index !== reviewsToDisplay.length - 1 ? <Divider /> : <></>}
                  </LayoutVerticalSpacer>
                </li>
              ))}
            </ul>
            {hasPagination && (
              <Pagination
                currentOffset={currentOffset}
                isClientSide
                label="reviews"
                linkComponent={Link as LinkComponent}
                onPageChange={handleButtonClick}
                pageHrefTemplate={truncatedUrl}
                pageSize={currentSize}
                totalItems={currentTotal}
              />
            )}
          </div>
        ) : (
          <div className={styles.noReview}>
            <Body>{firstName} doesn&apos;t have any reviews yet.</Body>
          </div>
        )}
      </LayoutVerticalSpacer>

      <div className={styles.divider}>
        <Divider />
      </div>
    </div>
  );
};
