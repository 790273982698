import classnames from 'classnames';
import { useField } from 'formik';

import styles from './ErrorBoundary.module.css';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  fieldName: string;
};

const ErrorBoundary = ({ fieldName, children }: Props): ReactElement => {
  const [, { error, touched }] = useField(fieldName);

  return (
    <div className={classnames({ [styles.root]: !!error && touched })}>
      <>
        {children}
        {!!error && touched && (
          <div className={styles.error} data-error-message>
            {error}
          </div>
        )}
      </>
    </div>
  );
};

export { ErrorBoundary };
