import {
  DOG_GROOMING_SERVICE_ID,
  DOG_TRAINING_SERVICE_ID,
  DOG_WALKING_SERVICE_ID,
  HOUSE_VISITING_SERVICE_ID,
  PET_DAY_CARE_SERVICE_ID,
  PET_HOSTING_SERVICE_ID,
  PET_SITTING_SERVICE_ID,
} from '~/common/constants/app';

import type { RateDetailsConfig } from './types';

export const PER_PUPPY_PER_NIGHT = 'Per puppy per night';
export const PER_PUPPY_PER_DAY = 'Per puppy per day';
export const ADDED_EACH_PUPPY = 'Added each puppy';

export const ADDITIONAL_PET_RATE_CONFIG: RateDetailsConfig = {
  title: 'Additional pet',
  rateValuePrefix: '$',
};

export const PUPPY_SURCHARGE_CONFIG: RateDetailsConfig = {
  description: {
    [PET_HOSTING_SERVICE_ID]: PER_PUPPY_PER_NIGHT,
    [PET_SITTING_SERVICE_ID]: PER_PUPPY_PER_NIGHT,
    [PET_DAY_CARE_SERVICE_ID]: PER_PUPPY_PER_DAY,
    [HOUSE_VISITING_SERVICE_ID]: PER_PUPPY_PER_DAY,
    [DOG_WALKING_SERVICE_ID]: ADDED_EACH_PUPPY,
    [DOG_GROOMING_SERVICE_ID]: ADDED_EACH_PUPPY,
    [DOG_TRAINING_SERVICE_ID]: ADDED_EACH_PUPPY,
  },
  title: 'Puppy surcharge',
  rateValuePrefix: '+$',
};

export const EASTER_SURCHARGE_DATES: { [key: string]: { end: string; start: string } } = {
  '2024': { start: '03-27', end: '04-03' },
  '2025': { start: '04-16', end: '04-23' },
  '2026': { start: '04-01', end: '04-08' },
  '2027': { start: '03-24', end: '03-31' },
};

export const SEASONAL_SURCHARGE_RATE_CONFIG: RateDetailsConfig = {
  title: 'Seasonal surcharge',
  description: 'Added during peak periods',
  rateValuePrefix: '+$',
};

export const EXT_STAY_DISCOUNT_CONFIG: RateDetailsConfig = {
  title: 'Long booking discount',
  description: (extStayTime) => `Greater than ${extStayTime} nights`,
  rateValuePostfix: '%',
};
