import { Pills } from '@madpaws/design-system';
import { IconStar } from '@madpaws/design-system';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import React from 'react';

import { QuoteText } from '~/tab-manager/ui';

import styles from './Review.module.css';
import { findServiceById, formatDateIntoMonthYear } from '../../utils';
import { DotDivider } from '../DotDivider/DotDivider';

import type { ReactElement } from 'react';
import type { SitterReview } from '~/common/types/sitter';

type ReviewProps = {
  review: SitterReview;
};

const StyledRating = styled(Rating)({
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '96px',
  width: '96px',
  '& .MuiRating-iconFilled': {
    color: '#f7c457',
  },
  '& .MuiRating-iconEmpty': {
    color: '#eaeaea',
  },
});

const REVIEW_TRUNCATION_POINT = 225;

export const Review = ({ review }: ReviewProps): ReactElement | null => {
  // const isMediumFromViewport = useMediumFromMediaQuery();
  const { avatar, name, isVerifiedCustomer, description, rate, createdAt, serviceTypeId } = review;

  return (
    <div className={styles.root}>
      <div className={styles.reviewerContent}>
        <div className={styles.avatar}>
          <img alt={name} src={avatar} />
        </div>
        <div className={styles.reviewerInformation}>
          <div>
            <div className={styles.reviewerName}>{name}</div>
            <div className={styles.reviewServiceAndDate}>
              <div>{findServiceById(serviceTypeId || 0)?.title}</div>
              {isVerifiedCustomer && <DotDivider />}
              <div>{formatDateIntoMonthYear(createdAt)}</div>
            </div>
          </div>
          <div className={styles.verifiedCustomerPill}>
            <Pills values={isVerifiedCustomer ? ['Verified customer'] : ['Testimonial']} />
          </div>
        </div>
      </div>
      <div className={styles.ratingAndDescription}>
        {isVerifiedCustomer && (
          <div className={styles.rating}>
            <StyledRating
              emptyIcon={<IconStar />}
              icon={<IconStar />}
              name={`${name}-rating-for-sitter`}
              readOnly
              value={rate}
            />
          </div>
        )}
        <QuoteText
          hasMaxWidth
          tabElement={{
            quoteTextItems: description.split('\n'),
            align: 'left',
            title: `Review by ${name}`,
          }}
          truncationPoint={REVIEW_TRUNCATION_POINT}
          withoutBackground
        />
      </div>
    </div>
  );
};
