import { Heading, IconStar } from '@madpaws/design-system';

import styles from './BasicSitterCard.module.css';
import { ReviewsLink } from '../../components/ReviewsLink/ReviewsLink';

import type { ReactElement } from 'react';
import type { SitterProfileStickyHeader } from '~/common/types/sitter';

export type Props = {
  stickyHeader: SitterProfileStickyHeader;
};

export const BasicSitterCard = ({ stickyHeader }: Props): ReactElement => {
  const { area, avatar, firstName, rating, totalReviews } = stickyHeader;

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.avatar}>
          <img alt={`Thumbnail of ${firstName}`} src={avatar} />
        </div>

        <div className={styles.sitterInformation}>
          <div className={styles.firstRow}>
            <Heading fontFamily="heading" size={'small1x'}>
              {firstName}
            </Heading>
          </div>
          <div className={styles.secondRow}>
            <div className={styles.rating}>
              <IconStar /> {rating.toFixed(1)}
            </div>
            <ReviewsLink totalReviews={totalReviews} />
            <div className={styles.verticalDivider} />
            <div className={styles.area}>{area}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
