import {
  Divider,
  Heading,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import { FAQ } from '~/common/components/FAQs/FAQ/FAQ';

import { mapObjectToQnAProps } from '../utils';

import type { ReactElement } from 'react';
import type { SitterQuestionsAndAnswers } from '~/common/types/sitter';

type Props = {
  questionsAndAnswers: SitterQuestionsAndAnswers;
};

export const QnA = ({ questionsAndAnswers }: Props): ReactElement | null => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const mappedQnA = mapObjectToQnAProps(questionsAndAnswers);

  if (!mappedQnA.length) {
    return null;
  }

  return (
    <div>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>Q&A</Heading>
      </LayoutVerticalSpacer>

      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
        <div>
          {mappedQnA.map((qna, index) => (
            <FAQ key={`faq_${index}`} {...qna} />
          ))}
        </div>
      </LayoutVerticalSpacer>
      <Divider />
    </div>
  );
};
