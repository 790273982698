import {
  useMediumFromMediaQuery,
  Heading,
  Divider,
  LayoutVerticalSpacer,
} from '@madpaws/design-system';
import React from 'react';

import { StaticMap } from '~/components/StaticMap/StaticMap';

import styles from './SitterNeighbourhood.module.css';

import type { ReactElement } from 'react';
import type { SitterProfileHeigbourhood } from '~/common/types/sitter';

type SitterNeighbourhoodProps = {
  neighbourhood: SitterProfileHeigbourhood;
};

export const SitterNeighbourhood = ({ neighbourhood }: SitterNeighbourhoodProps): ReactElement => {
  const { lng, lat, firstName, area } = neighbourhood;
  const isMediumFromViewport = useMediumFromMediaQuery();
  const title = `${firstName}'s neighbourhood`;
  const mapZoom = isMediumFromViewport ? 13 : 12;

  return (
    <>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>{title}</Heading>
      </LayoutVerticalSpacer>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'default' : 'small2x'}>
        <StaticMap lat={lat} lng={lng} zoom={mapZoom} />
      </LayoutVerticalSpacer>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large3x' : 'large1x'}>
        <label className={styles.areaText}>{area}</label>
      </LayoutVerticalSpacer>

      <Divider />
    </>
  );
};
