import { gql } from '@apollo/client';

export const CALCULATE_BOOKING_PRICE = gql`
  query calculateBookingPrices(
    $sitterId: Int!
    $serviceTypeId: Int!
    $petTypes: String!
    $scheduledDates: String
    $startDate: String
    $endDate: String
  ) {
    calculateBookingPrices(
      sitterId: $sitterId
      serviceTypeId: $serviceTypeId
      petTypes: $petTypes
      scheduledDates: $scheduledDates
      startDate: $startDate
      endDate: $endDate
    ) {
      preview {
        averagePrice {
          string
          value
        }
        basePrice {
          string
          value
        }
        totalPrice {
          string
          value
        }
      }
      price
      singlePetRate
    }
  }
`;

export const GET_AVAILABLE_WEEKDAYS = gql`
  query availableWeekdays(
    $sitterId: Int!
    $serviceTypeId: Int!
    $petTypes: String!
    $weekDays: String!
    $startDate: String!
  ) {
    availableWeekdays(
      sitterId: $sitterId
      serviceTypeId: $serviceTypeId
      petTypes: $petTypes
      weekDays: $weekDays
      startDate: $startDate
    ) {
      weekDays
      error {
        detail
        field
        title
      }
    }
  }
`;
