import {
  BIRD_PET_TYPE_ID,
  CAT_PET_TYPE_ID,
  CRITTER_PET_TYPE_ID,
  DOG_LARGE_PET_TYPE_ID,
  DOG_MEDIUM_PET_TYPE_ID,
  DOG_PUPPY_PET_TYPE_ID,
  DOG_SMALL_PET_TYPE_ID,
  DOG_XLARGE_PET_TYPE_ID,
} from '~/common/constants/app';

import { sitterAcceptsPetType } from '../utils';

import type { SitterProfileAcceptedPetType } from '~/common/types/sitter';

export type PetTypesEventProperties = {
  bird_accepted: boolean;
  cat_accepted: boolean;
  critter_accepted: boolean;
  giant_dog_accepted: boolean;
  large_dog_accepted: boolean;
  medium_dog_accepted: boolean;
  puppy_accepted: boolean;
  small_dog_accepted: boolean;
};

export const getPetTypesAcceptedProperties = (
  petsAccepted: SitterProfileAcceptedPetType[]
): PetTypesEventProperties => ({
  bird_accepted: sitterAcceptsPetType(BIRD_PET_TYPE_ID, petsAccepted),
  cat_accepted: sitterAcceptsPetType(CAT_PET_TYPE_ID, petsAccepted),
  critter_accepted: sitterAcceptsPetType(CRITTER_PET_TYPE_ID, petsAccepted),
  giant_dog_accepted: sitterAcceptsPetType(DOG_XLARGE_PET_TYPE_ID, petsAccepted),
  large_dog_accepted: sitterAcceptsPetType(DOG_LARGE_PET_TYPE_ID, petsAccepted),
  medium_dog_accepted: sitterAcceptsPetType(DOG_MEDIUM_PET_TYPE_ID, petsAccepted),
  puppy_accepted: sitterAcceptsPetType(DOG_PUPPY_PET_TYPE_ID, petsAccepted),
  small_dog_accepted: sitterAcceptsPetType(DOG_SMALL_PET_TYPE_ID, petsAccepted),
});
