import { LayoutVerticalSpacer } from '@madpaws/design-system';
import dynamic from 'next/dynamic';
import { useState } from 'react';
import 'yet-another-react-lightbox/styles.css';

import { trackEvent } from '~/components/analytics/analytics';
import { VIEWED_SITTER_PHOTO_GALLERY_EVENT_NAME } from '~/components/analytics/constants';

import styles from './PhotoGalleryDesktop.module.css';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';

import type { ReactElement } from 'react';
import type { SitterPhoto } from '~/common/types/sitter';

const Lightbox = dynamic(
  () => import('yet-another-react-lightbox').then((module) => module.default),
  { ssr: false }
);

type Props = {
  photos: SitterPhoto[];
  sitterId: number;
};

export const PhotoGalleryDesktop = ({ photos, sitterId }: Props): ReactElement | null => {
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState(0);
  if (!photos.length) {
    return null;
  }

  const onPhotosOpen = (imageIndex: number): void => {
    setIndex(imageIndex);
    setOpen(true);

    trackEvent(VIEWED_SITTER_PHOTO_GALLERY_EVENT_NAME, {
      ...getDefaultEventProperties(sitterId),
    });
  };

  const [first, second, third, fourth] = photos;
  const shouldShowButton = photos.length > 4;

  return (
    <LayoutVerticalSpacer space="large2x">
      <div className={styles.root}>
        <div className={styles.first}>
          {first && (
            <img
              alt="First photo in Sitter's gallery"
              onClick={(): void => onPhotosOpen(0)}
              src={first.src}
            />
          )}
        </div>

        <div className={styles.second}>
          {second && (
            <img
              alt="Second photo in Sitter's gallery"
              onClick={(): void => onPhotosOpen(1)}
              src={second.src}
            />
          )}
        </div>

        <div className={styles.third}>
          {third && (
            <img
              alt="Third photo in Sitter's gallery"
              onClick={(): void => onPhotosOpen(2)}
              src={third.src}
            />
          )}
        </div>

        <div className={styles.fourth}>
          {fourth && (
            <img
              alt="Fourth photo in Sitter's gallery"
              onClick={(): void => onPhotosOpen(3)}
              src={fourth.src}
            />
          )}
        </div>

        {/* TODO (WEB-1683): Update button to use DS stone variant button */}
        {shouldShowButton && (
          <button className={styles.button} onClick={(): void => onPhotosOpen(4)} type="button">
            See all photos
          </button>
        )}

        <Lightbox
          close={(): void => setOpen(false)}
          index={index}
          open={open}
          slides={photos.map((photo) => ({ src: photo.src, type: 'image' }))}
        />
      </div>
    </LayoutVerticalSpacer>
  );
};
