import {
  Body,
  Button,
  Dialog,
  Divider,
  IconHeartFilled,
  LayoutVerticalSpacer,
} from '@madpaws/design-system';
import { useRouter } from 'next/router';
import React from 'react';

import { SIGN_IN_ROUTE } from '~/common/constants/auth';
import { DOM_CONTAINER_ID } from '~/components/constants';
import { setLocalStorage } from '~/utils/localStorage';

import styles from './LoginRequiredDialog.module.css';
import { FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY } from './constants';

import type { ReactElement } from 'react';

type Props = {
  isOpen: boolean;
  onDialogClose: () => void;
  sitterId: number;
};

export const LoginRequiredDialog = ({ isOpen, sitterId, onDialogClose }: Props): ReactElement => {
  const router = useRouter();
  const onClick = (): void => {
    setLocalStorage(FAVORITE_SITTER_ID_LOCAL_STORAGE_KEY, sitterId);
    // navigate to sign in page with redirect url to sitter profile
    router.push(`${SIGN_IN_ROUTE}?redirectUrl=${router.asPath}`);
  };

  return (
    <Dialog
      domContainerId={DOM_CONTAINER_ID}
      heading="Favourite"
      isOpen={isOpen}
      onRequestClose={onDialogClose}
    >
      <div className={styles.content}>
        <div className={styles.icon}>
          <IconHeartFilled />
        </div>
        <Body size="default">Sign up or log in to favourite sitters.</Body>
      </div>
      <LayoutVerticalSpacer>
        <Divider />
      </LayoutVerticalSpacer>
      <div className={styles.buttonContainer}>
        <Button label="Cancel" onClick={onDialogClose} variant="tertiary" />
        <Button label="Continue" onClick={onClick} variant="primary" />
      </div>
    </Dialog>
  );
};
