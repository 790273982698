import { IconButton, IconChevronLeft } from '@madpaws/design-system';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import 'yet-another-react-lightbox/styles.css';

import { trackEvent } from '~/components/analytics/analytics';
import { VIEWED_SITTER_PHOTO_GALLERY_EVENT_NAME } from '~/components/analytics/constants';

import styles from './PhotoGalleryMobile.module.css';
import { ShareButton } from '../../components/ShareButton/ShareButton';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';

import type { ReactElement } from 'react';
import type { SitterPhoto, SitterProfileCard } from '~/common/types/sitter';

const Lightbox = dynamic(
  () => import('yet-another-react-lightbox').then((module) => module.default),
  { ssr: false }
);

type Props = {
  canonicalUrl: string | null;
  photos: SitterPhoto[];
  sitterCard: SitterProfileCard;
};

export const PhotoGalleryMobile = ({ canonicalUrl, photos, sitterCard }: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const { sitterId } = sitterCard;

  const onPhotosOpen = (): void => {
    setOpen(true);

    trackEvent(VIEWED_SITTER_PHOTO_GALLERY_EVENT_NAME, {
      ...getDefaultEventProperties(sitterId),
    });
  };

  const [first] = photos;
  const shouldShowButton = photos.length > 1;

  const handleBackClick = (): void => {
    // Check if the page was opened directly or from another page
    if (document.referrer === '') {
      // If opened directly, redirect to the home page
      window.location.href = '/';
    } else {
      // If opened via another page, go to the previous page in browser history
      window.history.back();
    }
  };

  return (
    <div className={styles.root}>
      {first && <img alt="Photo in Sitter's gallery" onClick={onPhotosOpen} src={first.src} />}

      <Lightbox
        close={(): void => setOpen(false)}
        open={open}
        slides={photos.map((photo) => ({ src: photo.src, type: 'image' }))}
      />

      <div className={styles.actions}>
        <IconButton label="Back" onClick={handleBackClick} variant="secondary">
          <IconChevronLeft />
        </IconButton>
        <ShareButton canonicalUrl={canonicalUrl} sitterCard={sitterCard} sitterId={sitterId} />
      </div>

      {/* TODO (WEB-1683): Update button to use DS stone variant button */}
      {shouldShowButton && (
        <button className={styles.button} onClick={onPhotosOpen} type="button">
          {photos.length} photos
        </button>
      )}
    </div>
  );
};
