import { LayoutVerticalSpacer, useMediumFromMediaQuery } from '@madpaws/design-system';
import React from 'react';

import styles from './SitterPetCard.module.css';

import type { ReactElement } from 'react';
import type { SitterProfilePet } from '~/common/types/pet';

type SitterPetsProps = {
  pet: SitterProfilePet;
};

export const SitterPetCard = ({ pet }: SitterPetsProps): ReactElement | null => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const { ageMonth, ageYears, avatar, breed, name } = pet;
  const ageYearDescription = ageYears > 1 ? `${ageYears} years` : `${ageYears} year`;
  const ageMonthDescription = ageMonth
    ? ageMonth > 1
      ? `${ageMonth} months`
      : `${ageMonth} month`
    : '';
  const petDescription = `${breed}, ${ageYearDescription} ${ageMonthDescription}`;

  return (
    <div className={styles.root}>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'default' : 'small2x'}>
        <div className={styles.wrapper}>
          <div />
          <img alt={`Picture of ${name}`} loading="lazy" src={avatar} />
        </div>
      </LayoutVerticalSpacer>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{petDescription}</div>
    </div>
  );
};
