import { useContext } from 'react';

import { AuthenticationDialogContext } from './AuthenticationProvider';
import { useUserLoggedInContext } from '../UserAuthProvider/UserAuthProvider';

type FeatureFlagsActions = {
  checkLoginAndOpenAuthDialog: (
    callBack: () => void,
    title: string,
    successRedirectUrl?: string
  ) => void;
  openAuthDialog: (title: string, successRedirectUrl?: string) => void;
};

export const useAuthDialog = (): FeatureFlagsActions => {
  const { openAuthDialog } = useContext(AuthenticationDialogContext);

  const { isUserLoggedIn } = useUserLoggedInContext();

  // WEB-2247 Disable login modal for now
  // const { treatments, isSplitIoLoading } = useSplitIO();

  // const isFeatureEnabled = !isSplitIoLoading
  //   ? treatments[FEATURE_HOLD_OUT_SPLIT_ID]
  //   === FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES.ON
  //   : false;
  const isFeatureEnabled = false;

  const checkLoginAndOpenAuthDialog = (
    callBack: () => void,
    title: string,
    successRedirectUrl?: string
  ): void => {
    if (!isUserLoggedIn && isFeatureEnabled) {
      openAuthDialog(title, successRedirectUrl);
      return;
    }

    callBack();
  };

  return { openAuthDialog, checkLoginAndOpenAuthDialog };
};
