import Router from 'next/router';
import { useEffect, useState } from 'react';

export const useRouterChangeLoader = (initialLoadingState: boolean): boolean => {
  const [isLoading, setLoading] = useState(initialLoadingState);
  const startLoading = (): void => setLoading(true);
  const stopLoading = (): void => setLoading(false);

  useEffect(() => {
    Router.events.on('routeChangeStart', startLoading);
    Router.events.on('routeChangeComplete', stopLoading);

    return (): void => {
      Router.events.off('routeChangeStart', startLoading);
      Router.events.off('routeChangeComplete', stopLoading);
    };
  }, []);

  return isLoading;
};
