import {
  Button,
  Heading,
  IconCheck,
  IconCopy,
  TappableLinkContainer,
} from '@madpaws/design-system';
import { useState } from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import { SHARED_SITTER_PROFILE_EVENT_NAME } from '~/components/analytics/constants';
import { SOCIAL_SHARE_LINKS } from '~/components/constants';

import styles from './ShareProfile.module.css';
import { getDefaultEventProperties } from '../../tracking/getDefaultEventProperties';

import type { MouseEvent, ReactElement } from 'react';
import type { SitterProfileCard } from '~/common/types/sitter';

export type Props = {
  canonicalUrl: string | null;
  sitterCard: SitterProfileCard;
  sitterId: number;
};

export const ShareProfile = ({ canonicalUrl, sitterCard, sitterId }: Props): ReactElement => {
  const [isCopied, setIsCopied] = useState(false);
  const { area, avatar, firstName } = sitterCard;

  const handleCopyToClipboard = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (canonicalUrl) {
      navigator.clipboard.writeText(`${canonicalUrl}?utm_source=copy&utm_medium=sitter_share`);
      setIsCopied(true);
    }

    trackEvent(SHARED_SITTER_PROFILE_EVENT_NAME, {
      share_channel: 'copy_link',
      ...getDefaultEventProperties(sitterId),
    });
  };

  const handleSocialShareClick = (channel: string): void => {
    trackEvent(SHARED_SITTER_PROFILE_EVENT_NAME, {
      share_channel: channel,
      ...getDefaultEventProperties(sitterId),
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.dialogContainer}>
        <div className={styles.dialogAvatar}>
          <img alt={`Thumbnail of ${firstName}`} src={avatar} />
        </div>
        <div className={styles.dialogInformation}>
          <Heading fontFamily="heading" size={'small1x'}>
            {firstName}
          </Heading>
          <div className={styles.area}>{area}</div>
        </div>
      </div>
      <div className={styles.dialogLinks}>
        <Button
          iconLeading={isCopied ? <IconCheck /> : <IconCopy />}
          isFullBleed
          label={isCopied ? 'Link copied' : 'Copy link'}
          onClick={handleCopyToClipboard}
          variant="secondary"
        />

        <div className={styles.socialShareLinks}>
          {SOCIAL_SHARE_LINKS.map(({ href, icon, label, share_channel }) => {
            const urlWithUTM = `${canonicalUrl}?utm_source=${share_channel}&utm_medium=sitter_share`;
            const encodedUrl = href.replace('{YOUR_URL}', encodeURIComponent(urlWithUTM));

            return (
              <TappableLinkContainer key={label}>
                <a
                  className={styles.anchorButton}
                  href={encodedUrl}
                  onClick={(): void => handleSocialShareClick(share_channel)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {icon}
                  {label}
                </a>
              </TappableLinkContainer>
            );
          })}
        </div>
      </div>
    </div>
  );
};
