import {
  useMediumFromMediaQuery,
  Heading,
  IconCalendarCheck,
  Divider,
  IconShieldCheckOutlined,
} from '@madpaws/design-system';
import React from 'react';

import { trackEvent } from '~/components/analytics/analytics';
import {
  CLICKED_LEARN_ABOUT_ACCIDENT_COVER_EVENT_NAME,
  CLICKED_LEARN_ABOUT_CANCELLATION_EVENT_NAME,
} from '~/components/analytics/constants';

import styles from './Policies.module.css';
import {
  MP_COVER_TITLE,
  MP_COVER_DESCRIPTION,
  MP_COVER_LINK,
  CANCELLATION_POLICY_TITLE,
  CANCELLATION_POLICY_LINK,
  CANCELLATION_POLICY_LEARN_MORE,
  MP_COVER_LEARN_MORE,
} from './constants';
import { getDefaultEventProperties } from '../tracking/getDefaultEventProperties';

import type { ReactElement } from 'react';
import type { SitterPolicy } from '~/common/types/sitter';

type PoliciesProps = {
  policies: SitterPolicy;
};

export const Policies = ({ policies }: PoliciesProps): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const { cancellationPolicy, sitterId } = policies;

  const clickedAccidentCover = (id: number): void => {
    trackEvent(CLICKED_LEARN_ABOUT_ACCIDENT_COVER_EVENT_NAME, {
      ...getDefaultEventProperties(id),
    });
  };

  const clickedCancellationPolicy = (id: number): void => {
    trackEvent(CLICKED_LEARN_ABOUT_CANCELLATION_EVENT_NAME, {
      ...getDefaultEventProperties(id),
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>{MP_COVER_TITLE}</Heading>
        <div className={styles.body}>
          <div>
            <IconShieldCheckOutlined size="large" />
          </div>
          <div className={styles.bodyText}>
            <p>{MP_COVER_DESCRIPTION}</p>
            <a
              href={MP_COVER_LINK}
              onClick={(): void => clickedAccidentCover(sitterId)}
              rel="noopener noreferrer"
              target="_blank"
            >
              {MP_COVER_LEARN_MORE}
            </a>
          </div>
        </div>
      </div>

      {!isMediumFromViewport && <Divider />}

      <div className={styles.section}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>
          {CANCELLATION_POLICY_TITLE}
        </Heading>
        <div className={styles.body}>
          <div>
            <IconCalendarCheck size="large" />
          </div>
          <div className={styles.bodyText}>
            <div>
              <p className={styles.title}>{cancellationPolicy.name}</p>
              <p>{cancellationPolicy.description}</p>
            </div>
            <a
              href={CANCELLATION_POLICY_LINK}
              onClick={(): void => clickedCancellationPolicy(sitterId)}
              rel="noopener noreferrer"
              target="_blank"
            >
              {CANCELLATION_POLICY_LEARN_MORE}
            </a>
          </div>
        </div>
      </div>

      {!isMediumFromViewport && <Divider />}
    </div>
  );
};
