import { Dialog, IconButton, IconShare } from '@madpaws/design-system';
import { useState } from 'react';

import { DOM_CONTAINER_ID } from '~/components/constants';

import { ShareProfile } from './ShareProfile';

import type { ReactElement } from 'react';
import type { SitterProfileCard } from '~/common/types/sitter';

export type Props = {
  canonicalUrl: string | null;
  sitterCard: SitterProfileCard;
  sitterId: number;
};

export const ShareButton = ({ canonicalUrl, sitterCard, sitterId }: Props): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = (): void => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = (): void => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton label="Share" onClick={handleDialogOpen} variant="secondary">
        <IconShare />
      </IconButton>
      <Dialog
        domContainerId={DOM_CONTAINER_ID}
        heading="Share this sitter"
        isOpen={isDialogOpen}
        onRequestClose={handleDialogClose}
      >
        <ShareProfile canonicalUrl={canonicalUrl} sitterCard={sitterCard} sitterId={sitterId} />
      </Dialog>
    </>
  );
};
