import {
  Divider,
  Heading,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';

import { AdditionalRates } from './AdditionalRates/AdditionalRates';
import { ServiceDetails } from './ServiceDetails/ServiceDetails';

import type { ReactElement } from 'react';
import type { SitterServicesOffered } from '~/common/types/sitter';

type ServicesOfferedProps = {
  servicesOffered: SitterServicesOffered;
};

// TODO (WEB-1193): Add UI adjustments for desktop and tablet
export const ServicesOffered = ({ servicesOffered }: ServicesOfferedProps): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  return (
    <>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size="small3x">Services Offered</Heading>
        <div>
          {servicesOffered.sitterProfileServices.map(
            ({ basePrice, timeUnit, serviceTypeId }, index) => {
              const hasDivider = index !== servicesOffered.sitterProfileServices.length - 1;

              return (
                <ServiceDetails
                  basePrice={basePrice}
                  hasDivider={hasDivider}
                  key={serviceTypeId}
                  serviceTypeId={serviceTypeId}
                  timeUnit={timeUnit}
                />
              );
            }
          )}
        </div>
      </LayoutVerticalSpacer>
      <AdditionalRates servicesOffered={servicesOffered} />
      {!isMediumFromViewport && <Divider />}
    </>
  );
};
