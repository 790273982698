import classnames from 'classnames';
import { useState } from 'react';

import styles from './QuoteText.module.css';

import type { ReactElement } from 'react';
import type { QuoteTextTabElement } from '~/common/types/pet';
import type { TabElementProps } from '~/tab-manager';

const TRUNCATION_POINT = 125;

const getHiddenVariant = (data: string[], truncationPoint: number): string[] | null => {
  let result = null;

  // If there are less than 3 quotes and none of them isn't long enough to
  // be cut, return null instead of hidden variant
  if (data.length < 3 && !data.some((text) => text.length > truncationPoint)) {
    return result;
  }

  // If there are more than 2 quotes, hidden variant will contain only
  // first two of them
  if (data.length > 2) {
    result = data.slice(0, 2);
  } else {
    result = data;
  }

  // If there are exact 2 quotes
  if (result.length === 2) {
    // Check if any if quotes is longer than needed
    const hasLongItem = result.some((text) => text.length > truncationPoint);

    // If the first of two qoutes is longer than truncation point, we pick only
    // first quote for further check
    if (hasLongItem && result[0].length > truncationPoint) {
      result = result.slice(0, 1);
    }

    // If the seconf of two qoutes is longer than truncation point, we pick the
    // first one as-is and truncated second
    if (hasLongItem && result[0].length <= truncationPoint) {
      return [result[0], `${result[1].substring(0, truncationPoint)}...`];
    }
  }

  // If there is only one quote - check if it long enough to cut
  if (result.length === 1 && result[0].length > truncationPoint) {
    return [`${result[0].substring(0, truncationPoint)}...`];
  }

  return result;
};

export const QuoteText = ({
  hasMaxWidth = false,
  onQuoteTextExpanded,
  tabElement,
  truncationPoint = TRUNCATION_POINT,
  withoutBackground = false,
}: TabElementProps<QuoteTextTabElement> & {
  hasMaxWidth?: boolean;
  onQuoteTextExpanded?: () => void;
  truncationPoint?: number;
  withoutBackground?: boolean;
}): ReactElement => {
  const [hidden, setHidden] = useState(true);

  const { title, quoteTextItems } = tabElement;

  if (!quoteTextItems || quoteTextItems.length === 0 || !title) {
    return <></>;
  }

  const hiddenVariant = getHiddenVariant(quoteTextItems, truncationPoint);

  const handleClick = (): void => {
    setHidden(!hidden);

    if (hidden && onQuoteTextExpanded) {
      onQuoteTextExpanded();
    }
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.withoutBackground]: withoutBackground,
        [styles.hasMaxWidth]: hasMaxWidth,
      })}
    >
      {!withoutBackground && <span>{title}</span>}
      {!hiddenVariant ? (
        <div>
          {quoteTextItems.map((text, index) => (
            <p key={`${index}-${text}`}>{text}</p>
          ))}
        </div>
      ) : (
        <>
          <div>
            {hidden
              ? hiddenVariant.map((possibleShorText) => (
                  <p key={possibleShorText}>{possibleShorText}</p>
                ))
              : quoteTextItems.map((text, index) => <p key={`${index}-${text}`}>{text}</p>)}
          </div>
          <button onClick={handleClick} type="button">
            {hidden ? 'More' : 'Less'}
          </button>
        </>
      )}
    </div>
  );
};
