import { Formik } from 'formik';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import {
  SITTER_PROFILE_PREFIX,
  OVERNIGHT_SERVICE_TYPE_IDS,
  SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS,
} from '~/common/constants/app';
import { useRouterChangeLoader } from '~/common/utils/hooks';
import { useAuthDialog } from '~/components/AuthenticationProvider/useAuthDialog';
import { useUserLoggedInContext } from '~/components/UserAuthProvider/UserAuthProvider';
import {
  BOOKING_TYPE_MARKETPLACE,
  BOOKING_TYPE_RWB,
  mapSearchFiltersToUrlQueryParameters,
} from '~/components/utils/search';

import { ContactSitterForm } from './ContactSitterForm/ContactSitterForm';
import { ContactSitterServicePrice } from './ContactSitterServicePrice/ContactSitterServicePrice';
import styles from './ContactSitterWidget.module.css';
import {
  calculateBookingPrice,
  contactWidgetValidationSchema,
  getFieldName,
  getAvailableWeekdays,
  getPayloadForAvailableWeekdays,
  getPayloadForCalculateBookingPrice,
  checkAvailability,
  getBookingTypeFromValues,
  getInitialServiceTypeId,
} from './utils';
import { filterSearchFilters, validateDates } from '../utils';

import type { FormikHelpers } from 'formik';
import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';
import type {
  SitterProfileSitterSettings,
  SitterProfileStickyComponent,
} from '~/common/types/sitter';

type ContactSitterWidgetProps = {
  ctaId: string;
  extraQueryParameters: string;
  id?: string;
  isContactButtonLoading: boolean;
  searchFilters: SearchFilters;
  setIsContactButtonLoading: (isContactButtonLoading: boolean) => void;
  sitterProfileSlug: string;
  sitterSettings: SitterProfileSitterSettings;
  stickyComponent: SitterProfileStickyComponent;
};

export const ContactSitterWidget = ({
  ctaId,
  extraQueryParameters,
  id,
  isContactButtonLoading,
  searchFilters: initialSearchFilters,
  setIsContactButtonLoading,
  stickyComponent,
  sitterProfileSlug,
  sitterSettings,
}: ContactSitterWidgetProps): ReactElement => {
  const { isUserLoggedIn } = useUserLoggedInContext();
  const isRouterLoading = useRouterChangeLoader(false);
  const [isFilteringSearchFilters, setIsFilteringSearchFilters] = useState<boolean>(true);
  const [unavailableDates, setUnavailableDates] = useState<string[]>([]);
  const [searchFilters, setSearchFilters] = useState<SearchFilters>(initialSearchFilters);
  const router = useRouter();

  const { baseRates, firstName, sitterId } = stickyComponent;

  const { checkLoginAndOpenAuthDialog } = useAuthDialog();

  const handleContactSitter = (newSearchFilters: SearchFilters): void => {
    const {
      location: {
        address: { suburb, stateName },
      },
    } = newSearchFilters;

    const LOCATION_SLUG = `${suburb.toLowerCase()}-${stateName.toLowerCase()}`;
    const SITTER_SLUG = sitterProfileSlug;
    const SEARCH_QUERY_PARAMS = mapSearchFiltersToUrlQueryParameters(newSearchFilters);

    let bookingEnquiryUrl = `/${SITTER_PROFILE_PREFIX}/${LOCATION_SLUG}/${SITTER_SLUG}/booking-enquiry?${SEARCH_QUERY_PARAMS}`;

    if (extraQueryParameters) {
      bookingEnquiryUrl += `&${extraQueryParameters}`;
    }

    // Provide sitter_name as query parameter in case user has to be logged-in
    // before being redirected to booking enquiry page
    if (!isUserLoggedIn && stickyComponent.firstName) {
      bookingEnquiryUrl += `&sitter_name=${stickyComponent.firstName}`;
    }

    checkLoginAndOpenAuthDialog(
      () => router.push(bookingEnquiryUrl),
      `Create a free account or log in to contact ${firstName}`,
      bookingEnquiryUrl
    );
  };

  const handleSubmit = async (
    values: SearchFilters,
    formikHelpers: FormikHelpers<SearchFilters>
  ): Promise<void> => {
    setIsContactButtonLoading(true);
    const payload = getPayloadForCalculateBookingPrice(values);
    const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[values.service.type];

    if (
      values.bookingType === BOOKING_TYPE_MARKETPLACE ||
      OVERNIGHT_SERVICE_TYPE_IDS.includes(serviceTypeId)
    ) {
      const { errors } = await calculateBookingPrice(sitterId, payload);

      if (errors && errors.length) {
        for (const fieldError of errors) {
          const { detail, field } = fieldError;

          formikHelpers.setFieldError(getFieldName(field), detail);
        }

        setIsContactButtonLoading(false);
        return;
      }
    }

    if (values.bookingType === BOOKING_TYPE_RWB) {
      const weekDaysPayload = getPayloadForAvailableWeekdays(values);

      const [
        { errors: calculateBookingErrors },
        { errors: getAwailableWeekdaysErrors },
      ] = await Promise.all([
        calculateBookingPrice(sitterId, payload),
        getAvailableWeekdays(sitterId, weekDaysPayload),
      ]);

      if (calculateBookingErrors || getAwailableWeekdaysErrors) {
        if (calculateBookingErrors && calculateBookingErrors.length) {
          for (const fieldError of calculateBookingErrors) {
            const { detail, field } = fieldError;

            formikHelpers.setFieldError(getFieldName(field), detail);
          }
        }

        if (getAwailableWeekdaysErrors && getAwailableWeekdaysErrors.length) {
          for (const fieldError of getAwailableWeekdaysErrors) {
            const { detail, field } = fieldError;

            formikHelpers.setFieldError(getFieldName(field), detail);
          }
        }

        setIsContactButtonLoading(false);
        return;
      }
    }

    handleContactSitter({
      ...searchFilters,
      bookingType: values.bookingType,
      chronology: {
        startDate: values.chronology.startDate,
        endDate: values.chronology.endDate,
        scheduledDates: values.chronology.scheduledDates,
        weekDays: values.chronology.weekDays,
        rwbStartDate: values.chronology.rwbStartDate,
      },
      petTypes: values.petTypes,
      service: {
        type: values.service.type,
        quantity: 0,
      },
    });

    setIsContactButtonLoading(false);
  };

  useEffect(() => {
    const initialCheck = async (): Promise<void> => {
      setIsFilteringSearchFilters(true);

      let finalUnavailableDates: string[] = [];

      const filteredSearchFilters = filterSearchFilters(initialSearchFilters, sitterSettings);
      const serviceTypeId = getInitialServiceTypeId(filteredSearchFilters, sitterSettings);
      const bookingType = getBookingTypeFromValues(filteredSearchFilters);

      if (bookingType === BOOKING_TYPE_RWB) {
        const weekDaysPayload = getPayloadForAvailableWeekdays(filteredSearchFilters);

        const [{ unavailableDates: newUnavailableDates }, { weekdays }] = await Promise.all([
          checkAvailability(sitterId, serviceTypeId, bookingType),
          getAvailableWeekdays(sitterId, weekDaysPayload),
        ]);

        filteredSearchFilters.chronology = validateDates(
          filteredSearchFilters,
          newUnavailableDates
        );

        if (weekdays) {
          const [sunday, monday, tuesday, wednesday, thursday, friday, saturday] = Object.values(
            weekdays
          );

          filteredSearchFilters.chronology.weekDays = {
            sunday: filteredSearchFilters.chronology.weekDays.sunday && sunday,
            monday: filteredSearchFilters.chronology.weekDays.monday && monday,
            tuesday: filteredSearchFilters.chronology.weekDays.tuesday && tuesday,
            wednesday: filteredSearchFilters.chronology.weekDays.wednesday && wednesday,
            thursday: filteredSearchFilters.chronology.weekDays.thursday && thursday,
            friday: filteredSearchFilters.chronology.weekDays.friday && friday,
            saturday: filteredSearchFilters.chronology.weekDays.saturday && saturday,
          };
        }

        finalUnavailableDates = newUnavailableDates;
      } else {
        const { unavailableDates: newUnavailableDates } = await checkAvailability(
          sitterId,
          serviceTypeId,
          bookingType
        );

        filteredSearchFilters.chronology = validateDates(
          filteredSearchFilters,
          newUnavailableDates
        );

        finalUnavailableDates = newUnavailableDates;
      }

      setSearchFilters(filteredSearchFilters);
      setUnavailableDates(finalUnavailableDates);

      setIsFilteringSearchFilters(false);
    };

    initialCheck();
  }, [sitterId, initialSearchFilters, sitterSettings]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={searchFilters || initialSearchFilters}
      onSubmit={handleSubmit}
      validationSchema={contactWidgetValidationSchema}
    >
      <div className={styles.root} id={id}>
        <ContactSitterServicePrice baseRates={baseRates} />
        <ContactSitterForm
          baseRates={baseRates}
          ctaId={ctaId}
          firstName={firstName}
          isContactButtonLoading={
            isRouterLoading || isContactButtonLoading || isFilteringSearchFilters
          }
          setIsContactButtonLoading={setIsContactButtonLoading}
          setUnavailableDates={setUnavailableDates}
          sitterId={sitterId}
          sitterSettings={sitterSettings}
          unavailableDates={unavailableDates}
        />
      </div>
    </Formik>
  );
};
