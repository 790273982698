import React from 'react';

import styles from './ReviewsLink.module.css';
import { CUSTOMER_REVIEWS_ID } from './constants';

import type { ReactElement } from 'react';

type Props = {
  totalReviews: number;
};

export const ReviewsLink = ({ totalReviews }: Props): ReactElement => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    // Prevent the default jump to the target element
    e.preventDefault();

    // Get the target element
    const targetElement = document.getElementById(e.currentTarget.hash.slice(1));

    if (targetElement) {
      // If the target element exists, smooth scroll into view
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <a className={styles.root} href={`#${CUSTOMER_REVIEWS_ID}`} onClick={handleClick}>
      ({totalReviews} ratings)
    </a>
  );
};
