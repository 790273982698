import { useLargeFromMediaQuery, IconPetDayCare, AnimatedSpinner } from '@madpaws/design-system';
import classnames from 'classnames';
import { useState, useEffect, useRef, useMemo } from 'react';

import styles from './StaticMap.module.css';

import type { ReactElement } from 'react';

export type Props = {
  description?: string;
  icon?: ReactElement;
  lat: number;
  lng: number;
  mapStyleId?: StyleId;
  zoom?: number;
};

type StyleId =
  | 'light-v11'
  | 'dark-v11'
  | 'streets-v12'
  | 'outdoors-v12'
  | 'satellite-v9'
  | 'satellite-streets-v12';

const STATIC_MAP_ZOOM = 15;
const STATIC_MAP_MAX_WIDTH = 1280;
const STATIC_MAP_HOST = 'https://api.mapbox.com';
const STATIC_MAP_DEFAULT_DESCRIPTION = `Pet sitter's neighbourhood`;

const getStaticMapUrl = (
  lng: number,
  lat: number,
  width: number,
  height: number,
  styleId: StyleId,
  zoom: number
): string | undefined => {
  const MAPBOX_API_KEY = process.env.NEXT_PUBLIC_MAPBOX_API_KEY;

  if (!MAPBOX_API_KEY) {
    return;
  }

  return `${STATIC_MAP_HOST}/styles/v1/mapbox/${styleId}/static/${lng},${lat},${zoom}/${width}x${height}?access_token=${MAPBOX_API_KEY}`;
};

export const StaticMap = ({
  description,
  lng,
  lat,
  icon,
  mapStyleId = 'streets-v12',
  zoom = STATIC_MAP_ZOOM,
}: Props): ReactElement => {
  const isLargeFromViewport = useLargeFromMediaQuery();
  const [width, setWidth] = useState(0);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [src, setSrc] = useState<string | undefined>('');
  const height = isLargeFromViewport ? 380 : 300;
  const containerRef = useRef<HTMLDivElement>(null);

  const onLoad = (): void => setIsImageLoading(false);
  const onError = (): void => setIsImageLoading(false);

  useEffect(() => {
    const calculateWidth = (): void => {
      if (containerRef.current) {
        const widthFromBounds = Math.round(containerRef.current.getBoundingClientRect().width);

        setWidth(widthFromBounds >= STATIC_MAP_MAX_WIDTH ? STATIC_MAP_MAX_WIDTH : widthFromBounds);
      }
    };

    calculateWidth();

    window.addEventListener('resize', calculateWidth);

    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, []);

  useEffect(() => {
    if (width) {
      const newSrc = getStaticMapUrl(lng, lat, width, height, mapStyleId, zoom);

      if (newSrc) {
        setSrc(newSrc);
      } else {
        setIsImageLoading(false);
      }
    }
  }, [lng, lat, height, width, mapStyleId, zoom]);

  const IconComponent = useMemo(() => (icon ? icon : <IconPetDayCare size="large" />), [icon]);

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.iconContainer}>
        <div className={styles.iconCircle}>
          {isImageLoading ? <AnimatedSpinner size="large" /> : IconComponent}
        </div>
      </div>

      <img
        alt={description || STATIC_MAP_DEFAULT_DESCRIPTION}
        className={classnames({ [styles.hide]: isImageLoading })}
        height={height}
        onError={onError}
        onLoad={onLoad}
        src={src}
        width={width}
      />
    </div>
  );
};
