import {
  Banner,
  Heading,
  IconBaby,
  IconBuilding,
  IconFence,
  IconPaw,
  IconSleep,
  IconNoHouse,
  LayoutVerticalSpacer,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import React from 'react';

import styles from './SitterHome.module.css';

import type { ReactElement } from 'react';
import type { SitterProfileHome } from '~/common/types/sitter';

type SitterHomeProps = {
  home: SitterProfileHome;
};

export const SitterHome = ({ home }: SitterHomeProps): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const {
    atHome: { hasChildrenAtHome, hasPetsAtHome },
    conditions: { backyard, servicesAtHome, property, sleep },
    firstName,
  } = home;

  if (servicesAtHome.value === false) {
    return (
      <div className={styles.root}>
        <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
          <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>
            {firstName}&apos;s home
          </Heading>
        </LayoutVerticalSpacer>
        <Banner
          description={`${firstName} doesn't offer services at their home`}
          icon={<IconNoHouse />}
        />
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <LayoutVerticalSpacer space={isMediumFromViewport ? 'large1x' : 'default'}>
        <Heading size={isMediumFromViewport ? 'small2x' : 'small3x'}>
          {firstName}&apos;s home
        </Heading>
      </LayoutVerticalSpacer>
      <div className={styles.mainContent}>
        <div>
          <div className={styles.sectionSubtitle}>The home</div>
          <div className={styles.homePropertyContainer}>
            <IconBuilding />
            <div className={styles.homePropertyValue}>{property}</div>
          </div>
          <div className={styles.homePropertyContainer}>
            <IconFence />
            <div className={styles.homePropertyValue}>{backyard}</div>
          </div>
          {sleep && (
            <div className={styles.homePropertyContainer}>
              <IconSleep />
              <div className={styles.homePropertyValue}>{sleep}</div>
            </div>
          )}
        </div>
        <div>
          <div className={styles.sectionSubtitle}>At the home</div>
          <div className={styles.homePropertyContainer}>
            <IconBaby />
            <div className={styles.homePropertyValue}>{hasChildrenAtHome}</div>
          </div>
          <div className={styles.homePropertyContainer}>
            <IconPaw />
            <div className={styles.homePropertyValue}>{hasPetsAtHome}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
