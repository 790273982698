import dayjs from 'dayjs';
import * as yup from 'yup';

import { client as apolloClient } from '~/api/graphql/client';
import {
  DEFAULT_SERVICE_TYPE_ID,
  OVERNIGHT_SERVICE_TYPE_IDS,
  SERVICE_TYPES_SUPPORTING_RWB,
  SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS,
} from '~/common/constants/app';
import { chronologyValidationObject, petTypesValidationObject } from '~/common/utils/validation';
import {
  BOOKING_TYPE_MARKETPLACE,
  BOOKING_TYPE_RWB,
  getPetTypesFromSearchFilters,
} from '~/components/utils/search';

import { CALCULATE_BOOKING_PRICE, GET_AVAILABLE_WEEKDAYS } from './queries';
import { GET_SITTER_AVAILABILITY } from '../serverSideProps/queries';

import type { BookingPrices, AvailableWeekdays, ValidationError } from '~/common/types/booking';
import type { SearchFilters, WeekDays } from '~/common/types/search';
import type { SitterProfileSitterSettings, SitterUnavailableDates } from '~/common/types/sitter';
import type { ServiceOption } from '~/components/constants';

interface CalculateBookingPriceResponse {
  bookingPrices: BookingPrices | null;
  errors: ValidationError[] | null;
}

interface AvailableWeekdaysResponse {
  errors: ValidationError[] | null;
  weekdays: AvailableWeekdays | null;
}

interface OvernightTypePayload {
  endDate: string;
  petTypes: string;
  serviceTypeId: number;
  startDate: string;
}

interface DaytimeTypePayload {
  petTypes: string;
  scheduledDates: string;
  serviceTypeId: number;
}

interface RepeatWeeklyTypePayload {
  petTypes: string;
  scheduledDates: string;
  serviceTypeId: number;
  type: typeof BOOKING_TYPE_RWB;
}

interface AvailableWeekdaysPayload {
  petTypes: string;
  serviceTypeId: number;
  startDate: string;
  weekDays: string;
}

export const contactWidgetValidationSchema = yup.object({
  petTypes: petTypesValidationObject,
  chronology: chronologyValidationObject,
});

export const generateDateRangeByWeekDays = (startDate: string, weekDays: WeekDays): string => {
  const [sunday, ...rest] = Object.values(weekDays).map((item, key) => ({
    dayKey: key || 7,
    dayValue: item,
  }));
  const weekDaysFromMonday = [...rest, sunday];

  return weekDaysFromMonday
    .filter(({ dayValue }) => dayValue)
    .map(({ dayKey }) =>
      dayjs(startDate)
        .add(dayKey - 1, 'days')
        .format('YYYY-MM-DD')
    )
    .join(', ');
};

/**
 * Get from search filters (form values)
 * payload required to calculate booking prices
 */
export const getPayloadForCalculateBookingPrice = (
  values: SearchFilters
): OvernightTypePayload | DaytimeTypePayload | RepeatWeeklyTypePayload => {
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[values.service.type];
  const petTypes = getPetTypesFromSearchFilters(values.petTypes);

  if (OVERNIGHT_SERVICE_TYPE_IDS.includes(serviceTypeId)) {
    const overnighTypetPayload: OvernightTypePayload = {
      endDate: values.chronology.endDate || '',
      petTypes,
      serviceTypeId,
      startDate: values.chronology.startDate || '',
    };

    return overnighTypetPayload;
  }

  if (
    values.bookingType === BOOKING_TYPE_RWB &&
    SERVICE_TYPES_SUPPORTING_RWB.includes(serviceTypeId)
  ) {
    const repeatWeeklyTypePayload: RepeatWeeklyTypePayload = {
      petTypes,
      serviceTypeId,
      type: values.bookingType,
      scheduledDates: generateDateRangeByWeekDays(
        values.chronology.rwbStartDate,
        values.chronology.weekDays
      ),
    };

    return repeatWeeklyTypePayload;
  }

  const daytimeTypePayload: DaytimeTypePayload = {
    petTypes,
    scheduledDates: values.chronology.scheduledDates?.toString() || '',
    serviceTypeId,
  };

  return daytimeTypePayload;
};

/**
 * Wrapper around graphql query to get Booking Prices,
 * created in order to allow get both data and errors
 */
export const calculateBookingPrice = async (
  sitterId: number,
  payload: OvernightTypePayload | DaytimeTypePayload | RepeatWeeklyTypePayload
): Promise<CalculateBookingPriceResponse> => {
  let bookingPrices = null;
  let errors = null;

  try {
    const {
      data: { calculateBookingPrices },
    } = await apolloClient.query({
      query: CALCULATE_BOOKING_PRICE,
      fetchPolicy: 'no-cache',
      variables: { sitterId, ...payload },
    });

    bookingPrices = calculateBookingPrices;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const validationErrors =
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions?.errors;

    if (validationErrors) {
      errors = validationErrors;
    }
  }

  return {
    bookingPrices,
    errors,
  };
};

/**
 * Get from search filters (form values)
 * payload required to get sitter's availdable weekdays
 * for RWB bookings
 */
export const getPayloadForAvailableWeekdays = (values: SearchFilters): AvailableWeekdaysPayload => {
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[values.service.type];
  const petTypes = getPetTypesFromSearchFilters(values.petTypes);

  const repeatWeeklyTypePayload: AvailableWeekdaysPayload = {
    startDate: values.chronology.rwbStartDate,
    petTypes,
    serviceTypeId,
    weekDays: [
      values.chronology.weekDays.sunday ? 1 : 0,
      values.chronology.weekDays.monday ? 1 : 0,
      values.chronology.weekDays.tuesday ? 1 : 0,
      values.chronology.weekDays.wednesday ? 1 : 0,
      values.chronology.weekDays.thursday ? 1 : 0,
      values.chronology.weekDays.friday ? 1 : 0,
      values.chronology.weekDays.saturday ? 1 : 0,
    ].join(','),
  };

  return repeatWeeklyTypePayload;
};

/**
 * Wrapper around graphql query to get Sitter's Available Weekdays,
 * created in order to allow get both data and errors for RWB bookings
 */
export const getAvailableWeekdays = async (
  sitterId: number,
  payload: AvailableWeekdaysPayload
): Promise<AvailableWeekdaysResponse> => {
  let weekdays = null;
  let errors = null;

  try {
    const {
      data: { availableWeekdays },
    } = await apolloClient.query({
      query: GET_AVAILABLE_WEEKDAYS,
      fetchPolicy: 'no-cache',
      variables: { sitterId, ...payload },
    });

    const { error: availableWeekdaysError, weekDays: weekdaysFromQuery } = availableWeekdays;

    weekdays = weekdaysFromQuery;
    errors = availableWeekdaysError ? [availableWeekdaysError] : null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const validationErrors =
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions?.errors;

    if (validationErrors) {
      errors = validationErrors;
    }
  }

  return {
    weekdays,
    errors,
  };
};

/**
 * Based on error object from API response
 * returns Formik's field name
 * that should be updated with received error message
 */
export const getFieldName = (fieldNameFromApi: string): string => {
  if (fieldNameFromApi === 'serviceTypeId') {
    return 'service';
  }

  if (
    ['scheduledDates', 'startDate', 'endDate', 'weekDays', 'rwbStartDate'].includes(
      fieldNameFromApi
    )
  ) {
    return 'chronology';
  }

  return fieldNameFromApi;
};

export const getBookingTypeFromValues = (values: SearchFilters): string => {
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[values.service.type];

  if (
    values.bookingType === BOOKING_TYPE_RWB &&
    SERVICE_TYPES_SUPPORTING_RWB.includes(serviceTypeId)
  ) {
    return BOOKING_TYPE_RWB;
  }

  return BOOKING_TYPE_MARKETPLACE;
};

export const filterProvidedServiceTypes = (
  serviceOptions: ServiceOption[],
  sitterSettings: SitterProfileSitterSettings
): ServiceOption[] =>
  serviceOptions.filter((option) =>
    sitterSettings.acceptedServiceTypes.includes(option.serviceTypeId)
  );

/**
 * Wrapper around graphql query to get array of unavailable days
 * for particular Sitter and service
 */
export const checkAvailability = async (
  sitterId: number,
  serviceTypeId: number,
  bookingType: string
): Promise<SitterUnavailableDates> => {
  const {
    data: { sitterAvailability },
  } = await apolloClient.query({
    query: GET_SITTER_AVAILABILITY,
    variables: {
      id: sitterId,
      serviceId: serviceTypeId,
      bookingType: bookingType,
    },
    fetchPolicy: 'no-cache',
  });

  return sitterAvailability;
};

/**
 * Get initial service type id based on search filters
 * or from sitter's settings in case of invalid service type
 */
export const getInitialServiceTypeId = (
  searchFilters: SearchFilters,
  sitterSettings: SitterProfileSitterSettings
): number => {
  const serviceTypeId = SERVICE_TYPE_SLUGS_TO_SERVICE_TYPE_IDS[searchFilters.service.type];

  if (serviceTypeId) {
    return serviceTypeId;
  }

  return sitterSettings.acceptedServiceTypes[0] || DEFAULT_SERVICE_TYPE_ID;
};
